<template>
  <div class="job-create-desktop">
    <div
        v-if="fetchLoading"
        class="d-flex align-center justify-center pa-16 ">
      <v-progress-circular
          color="main"
          indeterminate
      />
    </div>
    <v-card
        v-else
        :disabled="job.loading"
        :loading="job.loading"
        class="job-create-desktop__form">
      <v-card-title>
        <PageTitle
            class="w-100"
            icon="mdi-pencil"
            title="ویرایش آگهی کاریابی"/>
      </v-card-title>

      <v-card-text>

        <v-row>
          <v-col :cols="4">
            <v-text-field

                v-model="job.Title"
                hide-details
                outlined
                label="عنوان"
            />
          </v-col>
          <v-col :cols="4">
            <v-select

                v-model="job.jobcode"
                hide-details
                outlined
                label="شغل مورد نظر"
                :items="jobTypes"
                item-value="id"
                item-text="name"
            />
          </v-col>
          <v-col :cols="4">
            <v-select

                v-model="job.shiftwork"
                hide-details
                outlined
                label="شیفت کاری"
                :items="shiftItems"
                item-value="id"
                item-text="name"
            />
          </v-col>
          <v-col :cols="4" class="d-flex align-center justify-start">
            <v-text-field
                prepend-inner-icon="mdi-clock-time-three-outline"

                v-model="job.startinghours"
                hide-details
                outlined
                label="ساعت شروع"
                class="start-time-picker"
            />
            <date-picker
                type="time"
                :max="job.endtime"
                auto-submit
                v-model="job.startinghours"
                custom-input=".start-time-picker"
            />
            <div class="font-weight-bold text-body-2 black--text mx-2">
              تا
            </div>
            <v-text-field
                prepend-inner-icon="mdi-clock-time-three-outline"

                v-model="job.endtime"
                hide-details
                outlined
                label="ساعت پایانی"
                class="end-time-picker"
            />
            <date-picker
                type="time"
                :min="job.startinghours"
                auto-submit
                v-model="job.endtime"
                custom-input=".end-time-picker"
            />
          </v-col>
          <v-col :cols="4">
            <v-combobox

                v-model="job.locationsid"
                hide-details
                outlined
                label="منطقه کاری"
                :items="locations"
                item-value="id"
                item-text="name"
            />
          </v-col>
          <v-col :cols="4">
            <v-text-field

                v-model="job.expertise"
                hide-details
                outlined
                label="تخصص مورد نظر"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
              v-for="i in 4"
              :key="i"
              cols="3">
            <Uploader
                v-model="job['image'+i]"
                @change="(e)=>{
              addImage(e,i)
            }"
                width="100%"/>
          </v-col>

          <v-col :cols="12">
            <v-textarea
                outlined
                v-model="job.description"
                label="توضیحات"
                height="80"
            />
          </v-col>
          <v-col
              class="d-flex align-center justify-start"
              :cols="12">
            <v-btn
                large
                elevation="0"
                :loading="job.loading"
                class="white--text"
                @click="submitJob"
                color="main">
              ویرایش آگهی
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle"
import Uploader from "@/components/Uploader"
import {AppConfig} from "@Newfiling/Services";
import {find, update} from "@Newfiling/module-job/src/api";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

export default {
  name: 'DesktopJobCreate',

  components: {
    PageTitle,
    Uploader,
    datePicker: VuePersianDatetimePicker,
  },

  data() {
    return {
      fetchLoading: true,
      jobTypes: [],
      locations: [],
      shiftItems: [
        {
          id: 1,
          name: 'صبح تا ظهر',
        },
        {
          id: 2,
          name: 'عصر تا شب',
        },
        {
          id: 3,
          name: 'تمام',
        },
      ],
      job: {
        id: null,
        loading: false,
        Title: null,
        jobcode: null,
        shiftwork: null,
        startinghours: null,
        endtime: null,
        locationsid: null,
        expertise: null,
        description: '',
        image1: null,
        image2: null,
        image3: null,
        image4: null,
      }
    }
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfigs = (await AppConfig) || {};
      this.jobTypes = appConfigs?.joblist.map(item => {
        return {
          id: item.Id,
          name: item.Jobname,
        }
      })
      this.locations = appConfigs?.neighborhoods.map(item => {
        return {
          id: item.Id,
          name: item.NeighborhoodName,
        }
      })
      await this.fetchJob(this.$route.params.id);
    })
  },

  methods: {
    addImage(file, index) {
      this.job[`image${index}`] = file.url;
    },
    async submitJob() {
      this.job.loading = true;
      try {
        await update(this.job.id, {
          ...this.job,
          locationsid: this.job.locationsid?.id || this.job.locationsid,
        });
        this.$toast.success('آگهی کاریابی با موفقیت ویرایش شد.');
        await this.$router.push('/panel/jobs/list');
      } catch (e) {
        console.log(e)
      }
      this.job.loading = false;
    },

    async fetchJob(id) {
      this.fetchLoading = true;
      try {
        const res = (await find(id))?.data?.list || {};
        this.job = {
          ...this.job,
          id: res.id,
          Title: res.title,
          jobcode: res.jobcode,
          shiftwork: res.shiftwork,
          startinghours: res.startinghours,
          endtime: res.endtime,
          locationsid: this.locations.find(item=>item.id === res.locationsid),
          expertise: res.expertise,
          description: res.description,
          image1: res.image1,
          image2: res.image2,
          image3: res.image3,
          image4: res.image4,
        }
      } catch (e) {
        console.log(e)
      }
      this.fetchLoading = false;
    }
  }

}
</script>

<style scoped>
</style>
